    import './scss/main.scss'
    import './lib/animation.js'
    const screenHeight = () => {
        var vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const mq = window.matchMedia('(max-width: 840px)');

    function init(){

    }

    window.onload = (event) => {
        console.log('page is fully loaded');
        init();
        screenHeight();
    };

    window.addEventListener('resize', screenHeight())


