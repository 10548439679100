import { gsap } from "gsap";

//? - =========================  SLIDER 2  ========================= -//
// const slider1 = {
//     $prev: document.querySelector('.slider1 .btn-prev'),
//     $next: document.querySelector('.slider1 .btn-next'),
// }
const tl_slider1 = gsap.timeline( { delay: 3 })
    // first_ interaction
    .to('.hero__slider .hero__slider--picture:nth-child(1)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' })
    .from('.hero__slider .hero__slider--picture:nth-child(2)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')
    // .addPause()

    // second interaction
    .to('.hero__slider .hero__slider--picture:nth-child(2)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' }, '+=4')
    .from('.hero__slider .hero__slider--picture:nth-child(3)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')
    // .addPause()

    // third interaction
    .to('.hero__slider .hero__slider--picture:nth-child(3)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' }, '+=4')
    .from('.hero__slider .hero__slider--picture:nth-child(4)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')

    // 3th interaction
    .to('.hero__slider .hero__slider--picture:nth-child(4)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' }, '+=4')
    .from('.hero__slider .hero__slider--picture:nth-child(5)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')

    // 5th interaction
    .to('.hero__slider .hero__slider--picture:nth-child(5)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' }, '+=4')
    .from('.hero__slider .hero__slider--picture:nth-child(6)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')

    // 6th interaction
    .to('.hero__slider .hero__slider--picture:nth-child(6)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' }, '+=4')
    .from('.hero__slider .hero__slider--picture:nth-child(7)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')

    // 7th interaction
    .to('.hero__slider .hero__slider--picture:nth-child(7)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' }, '+=4')
    .from('.hero__slider .hero__slider--picture:nth-child(8)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')

    // 8th interaction
    // .to('.hero__slider .hero__slider--picture:nth-child(8)', { xPercent: -100, duration: 3,  ease: 'power3.inOut' }, '+=4')
    // .from('.hero__slider .hero__slider--picture:nth-child(1)', { xPercent: 100, duration: 3,  ease: 'power3.inOut' },'<')

// slider1.$prev.addEventListener('click', () => {
//     tl_slider1.reverse()
// })
//
// slider1.$next.addEventListener('click', () => {
//     tl_slider1.play()
// })
